<template>
  <Modal v-model="commodityEditModal" title="编辑商品信息" width="800">
      <h4>商品基础信息</h4>
      <Row class="p-b-5 p-t-10">
        <i-col span="3" ><span class="title">商品名称 </span ></i-col >
        <i-col span="9" >{{commodityBean.productName}}</i-col >

        <i-col span="3" ><span class="title">商品编码 </span ></i-col >
        <i-col span="9" >{{commodityBean.code}}</i-col >
      </Row>
      <Row class="p-b-5">
        <i-col span="3" ><span class="title">商品类型 </span ></i-col >
        <i-col span="9" >{{commodityBean.productTypeName}}</i-col >

        <i-col span="3" ><span class="title">核销档期 </span ></i-col >
        <i-col span="9" >{{commodityBean.verifyPeriod}}</i-col >
      </Row>
      <Row class="p-b-5">
        <i-col span="3" ><span class="title">置换单价 </span ></i-col >
        <i-col span="9" >{{commodityBean.replacementPrice}}</i-col >

        <i-col span="3" ><span class="title">总商品数量 </span ></i-col >
        <i-col span="9" >{{commodityBean.totalNum}}</i-col >
      </Row>
      <Row class="p-b-5">
        <i-col span="3" ><span class="title">总销售数量 </span ></i-col >
        <i-col span="9" >{{commodityBean.totalSoldNum}}</i-col >

        <i-col span="3" ><span class="title">上架销售数量 </span ></i-col >
        <i-col span="9" >{{commodityBean.soldNum}}</i-col >
      </Row>
      <Row class="p-b-5">
        <i-col span="3" ><span class="title">商品现价 </span ></i-col >
        <i-col span="9" >{{commodityBean.price}}</i-col >
      </Row>

      <h4 class="m-t-10">可编辑信息</h4>
      <Row class="p-b-10 p-t-10">
        <i-col span="3" ><span class="title">新增记录</span ></i-col >
        <i-col span="21" >
          <RadioGroup v-model="updateBean.isAdd">
              <Radio label="1">是</Radio>
              <Radio label="0">否</Radio>
          </RadioGroup>
          <span class="remark">(tips: 选择“是”，为新增一条商品记录，当前信息将被置为失效)</span>
        </i-col >
      </Row>

      <Row class="p-b-10">
        <i-col span="3" ><span class="title">上架日期 </span ></i-col >
        <i-col span="9" >
          <DatePicker v-model="updateBean.saleDate" type="date" placeholder="请选择上架日期" size="small"></DatePicker>
        </i-col >

        <i-col span="3" ><span class="title">预计下架日期 </span ></i-col >
        <i-col span="9" >
          <DatePicker v-model="updateBean.expectDownDate" type="date" placeholder="请选择预计下架日期" size="small"></DatePicker>
        </i-col >
      </Row>
      <Row class="p-b-10">
        <i-col span="3" ><span class="title">商品初始价 </span ></i-col >
        <i-col span="9" >
          <InputNumber v-model="updateBean.initPrice" size="small" :min="0"></InputNumber>
        </i-col >

        <i-col span="3" ><span class="title">最低保留价格 </span ></i-col >
        <i-col span="9" >
          <InputNumber v-model="updateBean.minPrice" size="small" :min="0"></InputNumber>
        </i-col >
      </Row>
      <Row class="p-b-10">
        <i-col span="3" ><span class="title">上架数量 </span ></i-col >
        <i-col span="9" >
          <InputNumber v-model="updateBean.listNum" size="small" :min="0"></InputNumber>
        </i-col >

        <i-col span="3" ><span class="title">开启动态定价 </span ></i-col >
        <i-col span="9" >
          <RadioGroup v-model="updateBean.dynamicFlag">
              <Radio label="true">是</Radio>
              <Radio label="false">否</Radio>
          </RadioGroup>
        </i-col >
      </Row>
      <Row class="p-b-5">
        <i-col span="3" ><span class="title">开启动态核销期 </span ></i-col >
        <i-col span="9" >
          <RadioGroup v-model="updateBean.openDynamicVerify">
              <Radio label="true">是</Radio>
              <Radio label="false">否</Radio>
          </RadioGroup>
        </i-col >
      </Row>

      <div slot="footer">
        <Button type="text" class="m-r-5" @click="commodityEditModal = false">取消</Button>
        <Button type="primary" @click="handleUpdateCommodity">确定</Button>
      </div>

    </Modal>
</template>

<script>
import { ParseDate } from '@/utils/dateFormat'
import { updateDutchRecord } from '@/api/displace/productDutch'

export default {
  data () {
    return {
      commodityEditModal: false,
      commodityBean: {},

      updateBean: {
        isAdd: null,
        productId: null,
        dutchRecordId: null,
        dynamicFlag: null,
        expectDownDate: null,
        initPrice: null,
        listNum: null,
        minPrice: null,
        openDynamicVerify: null,
        saleDate: null
      }
    }
  },
  methods: {
    showModal (params) {
      this.commodityEditModal = true
      this.commodityBean = params

      this.updateBean.dutchRecordId = params.dutchRecordId
      this.updateBean.productId = params.productId
      this.updateBean.isAdd = '1'
      this.updateBean.saleDate = params.saleDate === '-' ? null : params.saleDate
      this.updateBean.expectDownDate = params.expectDownDate === '-' ? null : params.expectDownDate
      this.updateBean.initPrice = params.initPrice === '-' ? null : parseFloat(params.initPrice)
      this.updateBean.minPrice = params.minPrice === '-' ? null : parseFloat(params.minPrice)
      this.updateBean.listNum = params.listNum === '-' ? null : parseInt(params.listNum)
      this.updateBean.openDynamicVerify = params.openDynamicVerify.toString()
      this.updateBean.dynamicFlag = params.dynamicFlag.toString()
    },
    handleUpdateCommodity () {
      this.updateBean.expectDownDate = this.updateBean.expectDownDate ? ParseDate(this.updateBean.expectDownDate) : null
      this.updateBean.saleDate = this.updateBean.saleDate ? ParseDate(this.updateBean.saleDate) : null

      updateDutchRecord(this.updateBean).then(res => {
        if (res.errcode === 0) {
          if (this.updateBean.isAdd === '0') {
            this.$emit('on-edit-success')
            this.$Notice.success({ desc: '编辑商品信息成功' })
          } else {
            this.$emit('on-add-success')
            this.$Notice.success({ desc: '新增商品记录成功' })
          }
          this.commodityEditModal = false
        } else {
          this.$Notice.error({ desc: res.errmsg })
        }
      })
    }
  }
}
</script>
